import React from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/Layout/PageWrapper';
import ModuleArea from '../components/ModularContent/ModuleArea';
import Hero from '../components/Hero/Hero';
import CollectionsList from '../components/CollectionsList/CollectionsList';
import Container from '../components/Layout/SharedStyles/Container';

// import { Navigator } from '../components/LanguageHelpers/Navigator';

const HomepageTemplate = ({
  data: {
    datoCmsHomepage: {
      title,
      heroHeading,
      heroSubheading,
      heroText,
      heroLink,
      heroLinkText,
      heroImageOne,
      heroImageTwo,
      collections,
      modularContent,
      collectionsHeading,
      metaTags,
    },
  },
  pageContext,
}) => {
  const scrollDown = () => {
    document.getElementById('contenttop').scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <PageWrapper home pageData={pageContext} metaTags={metaTags}>
      <Hero
        heading={heroHeading}
        subHeading={heroSubheading}
        text={heroText}
        link={heroLink}
        linkText={heroLinkText}
        imageOne={heroImageOne}
        imageTwo={heroImageTwo}
        onClickScrollDown={scrollDown}
      />
      {collections && collections.length > 0 && (
        <Container width="large" pb={4}>
          <CollectionsList
            collections={collections}
            heading={collectionsHeading || 'Our categories'}
          />
        </Container>
      )}
      <div id="contenttop" />
      {modularContent.length > 0 && <ModuleArea blocks={modularContent} />}
    </PageWrapper>
  );
};

export default HomepageTemplate;

export const query = graphql`
  query HomepageQuery($locale: String!) {
    datoCmsHomepage(locale: { eq: $locale }) {
      locale
      title
      metaTags {
        title
        description
        image {
          url
        }
      }
      heroImageOne {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { fm: "webp", h: "760", fit: "crop", w: "760", q: 60 }
        )
      }
      heroImageTwo {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { fm: "webp", h: "300", fit: "crop", w: "300", q: 60 }
        )
      }
      heroHeading
      heroSubheading
      heroText
      heroLink {
        ... on DatoCmsDealerPage {
          originalId
        }
        ... on DatoCmsOtherPage {
          originalId
        }
        ... on DatoCmsCollection {
          originalId
        }
        ... on DatoCmsContactPage {
          originalId
        }
      }
      heroLinkText
      collectionsHeading
      collections {
        ... on DatoCmsCollection {
          title
          id: originalId
          featureImage {
            gatsbyImageData(
              layout: CONSTRAINED
              imgixParams: { w: "400", h: "400", fit: "crop", q: 60 }
            )
            alt
          }
          internal {
            type
          }
        }
        ... on DatoCmsAllProductsPage {
          title
          id: originalId
          featureImage {
            gatsbyImageData(
              layout: CONSTRAINED
              imgixParams: { w: "400", h: "400", fit: "crop", q: 60 }
            )
            alt
          }
          internal {
            type
          }
        }
      }
      modularContent {
        ... on DatoCmsDetailedInfoBlock {
          ...DetailedInfoBlock
        }
        ... on DatoCmsLeadBlock {
          ...LeadBlock
        }
        ... on DatoCmsTestimonialsBlock {
          ...TestimonialsBlock
        }
        ... on DatoCmsGalleryBlock {
          ...GalleryBlock
        }
        ... on DatoCmsProductGridBlock {
          ...ProductGridBlock
        }
      }
    }
  }
`;
