import React from 'react';
import style from './circle-image.mod.scss';
import { GatsbyImage } from 'gatsby-plugin-image';
import cn from 'classnames';

const CircleImage = ({ image, border, className }) => {
  return (
    <div className={cn(style.circleImg, className, {[`${style.circleImg}--has-border`]: border})}>
      {border && <div className={style.circleImg__border} style={{backgroundImage: `url("${image.gatsbyImageData.images.fallback.src}")`}} />}
      <div className={style.circleImg__inner}>
        <GatsbyImage image={image.gatsbyImageData} alt={image.alt || ""} />
      </div>
    </div>
  );
};

export default CircleImage;
