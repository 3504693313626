import React from 'react';
import style from './hero.mod.scss';
import Container from '../Layout/SharedStyles/Container';
import CircleImage from '../CircleImage/CircleImage';
import Button from '../Button/Button';
import Arrow from '../Layout/Icons/Arrow';
import BgWave from '../Layout/Icons/BgWave';

const Hero = ({ heading, subHeading, text, link, linkText, imageOne, imageTwo, onClickScrollDown }) => {
  return (
    <div className={style.hero}>
      <Container width="large">
        <div className={style.hero__inner}>
          <div className={style.hero__images}>
            <CircleImage image={imageOne} className={style.hero__mainImg} border />
            <CircleImage image={imageTwo} className={style.hero__secondaryImg} />
          </div>
          <div className={style.hero__text}>
            <h1>{heading}</h1>
            <h2>{subHeading}</h2>
            <div dangerouslySetInnerHTML={{__html: text}} />
            {link && <div className={style.hero__link}>
              <Button primary recordId={link.originalId ? link.originalId : null}>{linkText}</Button>
            </div>}
            {typeof onClickScrollDown === "function" && <div className={style.hero__scrolldown}>
              <button onClick={onClickScrollDown}><Arrow /></button>
            </div>}
          </div>
        </div>
      </Container>
      <div className={style.hero__wave}><BgWave /></div>
    </div>
  );
};

export default Hero;
